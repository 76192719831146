import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/Vouchers/';

class HostVocherService {
  getVoucherTransactions(
    currentPage: number,
    sortColumn: string,
    sortAsc: boolean,
    tableFilter: string,
    voucherId: string
  ) {
    return ApiService.get(
      `${API_URL}VoucherTransactionIndex?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}&voucherId=${voucherId}`
    );
  }

  createTransaction(voucherTransaction: {
    note: string;
    voucherId: string;
    amount: string;
    voucherTransactionType: string;
  }) {
    return ApiService.post(`${API_URL}CreateTransaction`, voucherTransaction);
  }

  update(voucher: { id: string; isDeactivated: string | boolean }) {
    voucher.isDeactivated = voucher.isDeactivated.toString();
    return ApiService.post(`${API_URL}Edit`, voucher);
  }

  getEdit(voucherId: string) {
    return ApiService.get(`${API_URL}Edit?voucherId=${voucherId}`);
  }

  createNew(voucher: { voucherScopeType: string }) {
    return ApiService.post(`${API_URL}Create`, voucher);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string, displayAll: boolean) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}&displayAll=${displayAll}`
    );
  }
}

export default new HostVocherService();
