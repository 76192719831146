





































































































import Vue, { VueConstructor } from 'vue';
import HostVoucherService from '@/services/owner/HostVoucherService';
import DataTable from '@/components/shared/DataTable.vue';
import { MetaInfo } from 'vue-meta';
import { validationMixin } from 'vuelidate';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import HostVoucherCreateCard from './HostVoucherCreateCard.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';

const validations = {
  filter: {
    displayAll: {},
  },
};

class VoucherIndexDto {
  id!: string;
  code!: string;
  voucherScopeType = '';
  discountType = '';
  percentage!: string;
  minimalOrderAmount!: string;
  initialAmount!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: { DataTable, HostVoucherCreateCard, ValidatedCheckbox },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminVoucherIndex.meta.title').toString(),
    };
  },
  validations,
  mixins: [validationMixin, FormaterMixin],
  data() {
    return {
      filter: { displayAll: false },
      vouchers: [] as VoucherIndexDto[],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'createdAt', asc: false },
      currentPage: 1,
      loading: true,
      ready: false,
      availableVoucherDiscountTypes: [] as string[],
      tableFilter: {
        external: false,
        lazy: false,
        label: this.$t('components.DataTable.table-filter-label'),
        placeholder: this.$t('components.DataTable.table-filter-placeholder'),
      },
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
    filter: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminVoucherIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      HostVoucherService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue,
        this.filter.displayAll
      ).then((res) => {
        this.vouchers = res.value.vouchers.items.map((m: VoucherIndexDto) => ({
          ...m,
        }));
        this.availableVoucherDiscountTypes = res.value.availableVoucherDiscountTypes;
        this.pages = res.value.vouchers.totalPages;
        this.setBreadCrumb();
        this.loading = false;
        this.ready = true;
      });
    },
  },
});
