


































import Vue, { PropType, VueConstructor } from 'vue';
import HostVoucherService from '@/services/owner/HostVoucherService';
import { MetaInfo } from 'vue-meta';
import { validationMixin } from 'vuelidate';
import CreateCard from '@/components/forms/CreateCard.vue';
import ToastMessage from '@/models/ToastMessage';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { maxValue, minValue, required } from 'vuelidate/lib/validators';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';

const validations = {
  voucher: {
    discountType: { required },
    initialAmount: {},
    percentage: {
      minValue: minValue(0),
      maxValue: maxValue(100),
    },
  },
};

class VoucherIndexDto {
  id!: string;
  code!: string;
  voucherScopeType = '';
  discountType = '';
  percentage!: string;
  minimalOrderAmount!: string;
  initialAmount!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: { CreateCard, ValidatedSelect, ValidatedInput },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminVoucherIndex.meta.title').toString(),
    };
  },
  validations,
  mixins: [validationMixin, FormaterMixin],
  props: {
    availableVoucherDiscountTypes: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    let voucher = new VoucherIndexDto();
    voucher.percentage = '0';
    voucher.initialAmount = '0';
    return {
      voucher: voucher,
      loading: false,
      message: '',
    };
  },
  computed: {
    availableVoucherDiscountTypeOptions(): { value: string; label: string }[] {
      let options = this.availableVoucherDiscountTypes.map((type) => {
        return { value: type, label: type };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    },
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      return HostVoucherService.createNew(this.voucher).then(
        (resp) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.$router.push({
            name: 'HostVoucherEdit',
            params: { voucherId: resp.value },
          });
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },
  },
});
